import * as Sentry from '@sentry/astro';
import { SENTRY_DSN_URL } from 'astro:env/client';

Sentry.init({
  dsn: SENTRY_DSN_URL,

  integrations: [
    // Sentry.browserTracingIntegration(),
    // Sentry.replayIntegration({
    //   maskAllText: false,
    //   blockAllMedia: false,
    // }),
  ],

  // Define how likely traces are sampled. Adjust this value in production,
  // or use tracesSampler for greater control.
  // tracesSampleRate: import.meta.env.PROD ? 0.01 : 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  // replaysSessionSampleRate: import.meta.env.PROD ? 0.01 : 1.0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  // replaysOnErrorSampleRate: 1.0,
});
